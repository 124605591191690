<template>
    <PopupDialog
        :show="isHidensfw"
        :hasButtons="false"
        :closeable="false"
        @closeModal="notToClose"
        :isBlured="true">
        <template #bodyContainer>
            <div
                id="dialog"
                class="flex w-full flex-col border-[#444] bg-[#101010] bg-transparent py-4">
                <div class="flex flex-col items-center justify-center space-y-5">
                    <h1
                        class="mx-auto my-2 flex w-auto text-center text-xl font-bold text-gray-800 dark:text-gray-100">
                        Terms of Service & Privacy Policy
                    </h1>
                    <div class="max-h-48 space-y-4 overflow-auto">
                        <p class="warning">
                            Before continuing, please review and accept our Terms of Service and
                            Privacy Policy.
                        </p>
                        <div class="warning flex">
                            <div>
                                <input
                                    v-model="checked"
                                    type="checkbox"
                                    id="accept"
                                    class="mr-2 cursor-pointer rounded border-gray-300" />
                            </div>
                            <div>
                                <label for="accept" class="cursor-pointer"
                                    >I acknowledge that I have read and agree to the
                                    <a :href="route('tos')" target="_blank" class="text-mintgreen"
                                        >Terms of Service</a
                                    >
                                    and
                                    <a
                                        :href="route('privacy')"
                                        target="_blank"
                                        class="text-mintgreen"
                                        >Privacy Policy</a
                                    >.</label
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="mt-5 flex w-full flex-col items-center justify-between space-y-6 sm:my-6 sm:flex-row sm:justify-center sm:space-y-0">
                    <PrimaryButton
                        id="continue"
                        type="button"
                        :disabled="!checked"
                        @click.prevent="setnsfwcookie()"
                        class="dark:text-bg-mintgreen flex h-[48px] w-full max-w-[200px] justify-center border border-mintgreen text-center text-mintgreen hover:bg-mintgreen hover:text-black dark:bg-black dark:hover:bg-mintgreen sm:mr-6 sm:w-[196px] sm:w-[220px]"
                        >I Agree
                    </PrimaryButton>
                    <SecondaryButton
                        id="disagree"
                        type="button"
                        class="my-4 flex h-[48px] w-full max-w-[200px] justify-center text-center hover:bg-gray-200 dark:bg-[#444] dark:text-gray-900 dark:hover:bg-gray-500 sm:w-[196px] sm:w-[220px]"
                        @click.prevent="declinensfw">
                        I Disagree
                    </SecondaryButton>
                </div>
            </div>
        </template>
    </PopupDialog>
</template>
<style scoped>
    #dialog {
        border: 1px solid #444;
        border-radius: 6px;
        background-color: #101010;
        color: #999;
    }
    #continue {
        border-color: #65e8a4;
        color: #65e8a4;
        background-color: #000;
        border-radius: 25px;
    }

    #disagree {
        border-color: #999;
        color: #999;
        background-color: #000;
        border-radius: 25px;
    }

    #disagree:hover {
        background-color: #65e8a4;
        color: #000;
        border-color: #65e8a4;
    }

    #continue:hover {
        background-color: #65e8a4;
        color: #000;
    }

    ol {
        list-style-type: decimal;
        list-style-position: inside;
        padding-left: 20px;
    }

    .warning {
        padding: 0 40px;
    }
</style>
<script setup>
    import { ref, onBeforeMount } from 'vue'
    import PopupDialog from '@/Components/ui-components/PopupDialog.vue'
    import PrimaryButton from '@/Components/PrimaryButton.vue'
    import SecondaryButton from '@/Components/SecondaryButton.vue'

    const checked = ref(false)
    const isHidensfw = ref(false)
    const notToClose = () => {
        isHidensfw.value = true
    }

    onBeforeMount(() => {
        setTimeout(() => {
            isHidensfw.value = true
            let doc_cookie = document.cookie
            if (doc_cookie.includes('gabber_terms')) {
                isHidensfw.value = false
            } else {
                isHidensfw.value = true
            }
        }, 500)
    })

    function setnsfwcookie() {
        document.cookie = `gabber_terms='c29tZSBlbmNvZGVkIHZhbHVlcw==';secure: true; expires=${new Date(
            new Date().getTime() + 1000 * 60 * 60 * 24 * 365,
        ).toGMTString()}; path=/;`

        setTimeout(() => {
            isHidensfw.value = false
        }, 500)
    }

    const declinensfw = () => {
        window.location.href = '/'
    }
</script>
