import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { Inertia } from '@inertiajs/inertia'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import VueTailwindDatepicker from 'vue-tailwind-datepicker'
import { createPinia } from 'pinia'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Swiper from 'swiper/bundle'
import Layout from '@/Layouts/MasterLayout.vue'
import posthog from 'posthog-js'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import ProfileLayout from '@/Layouts/ProfileLayout.vue'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faLockOpen as fasLockOpen,
    faPeopleGroup as fasPeopleGroup,
    faUsers as fasUsers,
    faCirclePlus as fasCirclePlus,
} from '@fortawesome/free-solid-svg-icons'

/* @fortawesome/sharp-solid-svg-icons */
import {
    faMoon,
    faSunBright,
    faComment,
    faCommentDollar,
    faHandHoldingDollar,
    faUserPlus,
    faMessageCheck,
    faPhotoFilm,
    faCommentQuote,
    faUsers,
    faUserGroup,
    faBullhorn,
    faCircleUser as fassCircleUser,
    faBadgeCheck as fassBadgeCheck,
} from '@fortawesome/sharp-solid-svg-icons'

/* @fortawesome/sharp-light-svg-icons */

import { faBadgeCheck } from '@fortawesome/sharp-light-svg-icons'

/* @fortawesome/sharp-Regular-svg-icons */
import { faHeartCircleXmark } from '@fortawesome/sharp-regular-svg-icons'

/* @fortawesome/pro-solid-svg-icons fas*/
import {
    faFire as fasFire,
    faGrid as fasGrid,
    faUnlock as fasUnlock,
    faLinkSimple as fasLinkSimple,
    faClapperboardPlay as fasClapperboardPlay,
    faCartShopping as fasCartShopping,
    faMaximize,
    faHouse,
    faHeart as fasHeart,
    faPlay,
    faMessage,
    faShare,
    faDollarSign,
    faMagnifyingGlass,
    faEllipsisVertical,
    faChevronDown as fasChevronDown,
    faChevronUp,
    faPlus as fasPlus,
    faSignsPost,
    faXmark as fasXmark,
    faCircleCheck as fasCircleCheck,
    faCircleExclamation as fasCircleExclamation,
    faThumbtack,
    faCircleUser,
    faCoins,
    faAngleLeft,
    faAngleRight,
    faMessagePlus as fasMessagePlus,
    faExpand,
    faComment as fasComment,
    faCircleDollar as fasCircleDollar,
    faBookmark as fasBookmark,
    faCirclePlus,
    faCircleMinus as fasCircleMinus,
    faArrowLeft as fasArrowLeft,
    faRotate,
    faCommentSms,
    faCopy,
    faFlag,
    faLock as fasLock,
    faCirclePlay,
    faStar,
    faCircleArrowUp,
    faCircleInfo as fasCircleInfo,
    faHashtag,
    faCaretRight as fasCaretRight,
    faPenToSquare as fasPenToSquare,
    faBell as fasBell,
    faStars,
    faCircleBolt,
    faUserCheck as fasUserCheck,
    faGear as fasGear,
    faFilms,
    faClockRotateLeft,
    faList,
    faUsers as farUsers,
    faTag,
    faChartColumn as farChartColumn,
    faCircleEllipsis,
    faEllipsis,
    faShuffle,
    faCameraMovie,
    faMegaphone,
    faSpinner,
    faPencil,
    faRedo,
    faFilmSimple,
    faCircleUser as fasCircleUser,
    faExclamationTriangle,
    faFileVideo,
    faCommentDollar as fasCommentDollar,
    faSackDollar as fasSackDollar,
    faUserPlus as fasUserPlus,
    faHandWave as fasHandWave,
    faCalendarClock as fasCalendarClock,
    faFaceGrinWide as fasFaceGrinWide,
    faBadgeCheck as fasBadgeCheck,
    faUserGroup as fasUserGroup,
    faPaperPlane as fasPaperPlane,
    faChevronsUp,
    faChevronsDown,
    faHandHoldingDollar as fasHandHoldingDollar,
    faLink as fasLink,
    faGlobe,
    faSquareChevronRight,
    faSquareChevronDown,
    faCrown,
    faCommentsDollar as fasCommentsDollar,
    faCreditCardFront as fasCreditCardFront,
    faFileInvoiceDollar as fasFileInvoiceDollar,
    faUserSecret,
    faPhotoFilm as fasPhotoFilm,
    faExpandWide as fasExpandWide,
    faVolume as fasVolume,
    faVolumeXmark as fasVolumeXmark,
    faSensorTriangleExclamation as fasSensorTriangleExclamation,
    faSignalStream as fasSignalStream,
} from '@fortawesome/pro-solid-svg-icons'

/* @fortawesome/pro-light-svg-icons */
import { faMessages, faCommentSmile, faBellSlash } from '@fortawesome/pro-light-svg-icons'

/* @fortawesome/pro-regular-svg-icons */
import {
    faEnvelope,
    faBell,
    faTrashCan,
    faPenToSquare,
    faNewspaper,
    faCircleCheck,
    faBookmark,
    faUser,
    faCalendarCheck,
    faHeart,
    faCircleUser as farCircleUser,
    faCircleCamera,
    faPlus,
    faChevronDown,
    faCircleInfo,
    faLockKeyhole,
    faUserPen,
    faCircleNodes,
    faShareNodes,
    faGearComplex,
    faHeadset,
    faArrowRightFromBracket,
    faUserCheck,
    faMoneyCheckDollarPen,
    faArrowUpFromSquare,
    faSignalStream,
    faTally,
    faChartColumn,
    faReceipt,
    faUsersLine,
    faUserGear,
    faArrowLeft,
    faCheck,
    faEye,
    faEyeSlash,
    faMoonStars,
    faCreditCard,
    faBlinds,
    faArrowRight,
    faThumbsUp,
    faFacePensive,
    faLock,
    faXmark,
    faHandLove,
    faFileInvoiceDollar,
    faBan,
    faCreditCardBlank,
    faSquareDashedCirclePlus,
    faVolumeSlash,
    faVolume,
    faPlusCircle,
    faCloudCheck,
    faUserUnlock,
    faSquareUser,
    faUserLock,
    faRectangleVerticalHistory,
    faGrid2Plus,
    faGrid2,
    faTrashCanList,
    faScrewdriverWrench,
    faFilm,
    faCheckDouble,
    faCamera,
    faLinkHorizontal,
    faCartShopping,
    faMessagePlus,
    faMessageDots,
    faCircleDollar,
    faCircleExclamation,
    faGear,
    faUnlock,
    faGrid,
    faVideo,
    faTags,
    faLink,
    faWallet,
    faMessageLines,
    faCircleMinus,
    faComment as farComment,
    faCommentSmile as farCommentSmile,
} from '@fortawesome/pro-regular-svg-icons'

/* @fortawesome Brands */

import {
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faTwitter,
    faTelegram,
    faWhatsapp,
    faReddit,
    faTiktok,
    faInstagram,
    faTwitter as faX,
    faXTwitter,
} from '@fortawesome/free-brands-svg-icons'

import { faCreditCardFront } from '@fortawesome/pro-light-svg-icons'
import PrimeVue from 'primevue/config'
import Tailwind from 'primevue/passthrough/tailwind'

import VueDatePicker from '@vuepic/vue-datepicker'
/* add icons to the library */
library.add(
    fasCirclePlus,
    fasUsers,
    fasPeopleGroup,
    fassCircleUser,
    fasCircleUser,
    faFilmSimple,
    fasFire,
    fasPlus,
    fasGrid,
    faCircleEllipsis,
    faEllipsis,
    farChartColumn,
    faTag,
    farUsers,
    faList,
    faClockRotateLeft,
    faFilms,
    faX,
    faInstagram,
    fasGear,
    fasUserCheck,
    faCircleBolt,
    faStars,
    faCircleCamera,
    fasBell,
    fasPenToSquare,
    fasLinkSimple,
    fasCartShopping,
    fasClapperboardPlay,
    faHashtag,
    faCircleArrowUp,
    faStar,
    faCirclePlay,
    fasLock,
    faFlag,
    faCopy,
    faTelegram,
    faWhatsapp,
    faReddit,
    faCommentSms,
    fasArrowLeft,
    faCirclePlus,
    faCircleMinus,
    fasCircleMinus,
    faCircleDollar,
    fasBookmark,
    faMessages,
    faCoins,
    fasXmark,
    faPlay,
    fasComment,
    faExpand,
    faMessagePlus,
    fasMessagePlus,
    faMagnifyingGlass,
    faMoon,
    faSunBright,
    faChevronUp,
    faXmark,
    faChevronDown,
    faMaximize,
    faHeart,
    faMessage,
    faShare,
    faDollarSign,
    faCircleUser,
    faPlusCircle,
    faVideo,
    faLockKeyhole,
    faBell,
    faEnvelope,
    faUserPen,
    faMoonStars,
    faCircleNodes,
    faShareNodes,
    faGearComplex,
    faHeadset,
    faArrowRightFromBracket,
    faComment,
    faUnlock,
    faHandHoldingDollar,
    faCommentDollar,
    faUserPlus,
    faMessageCheck,
    faPhotoFilm,
    faScrewdriverWrench,
    faCommentQuote,
    faUsers,
    faUserGroup,
    faCircleExclamation,
    fasCircleExclamation,
    faBullhorn,
    faEllipsisVertical,
    faUserCheck,
    faMoneyCheckDollarPen,
    faArrowUpFromSquare,
    faSignalStream,
    faTally,
    faChartColumn,
    faReceipt,
    faUsersLine,
    faUserGear,
    faArrowLeft,
    faRotate,
    faTrashCan,
    faBadgeCheck,
    faCloudCheck,
    faCheck,
    faEye,
    faEyeSlash,
    faCreditCard,
    faBlinds,
    faArrowRight,
    faHouse,
    faThumbsUp,
    faFacePensive,
    faLock,
    faNewspaper,
    faXmark,
    faHandLove,
    faFileInvoiceDollar,
    faBan,
    faCreditCardBlank,
    faSquareDashedCirclePlus,
    faPenToSquare,
    faCircleCheck,
    faVolumeSlash,
    faVolume,
    faRectangleVerticalHistory,
    faBookmark,
    faGrid2Plus,
    faGrid2,
    faTrashCanList,
    faPlus,
    faSignsPost,
    fasCircleCheck,
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCreditCardFront,
    faUserUnlock,
    faSquareUser,
    faUserLock,
    faFilm,
    faCheckDouble,
    faCamera,
    faLinkHorizontal,
    faCartShopping,
    faThumbtack,
    faMessageDots,
    faGear,
    faUser,
    faCalendarCheck,
    faAngleLeft,
    faAngleRight,
    fasHeart,
    fasCircleDollar,
    fasUnlock,
    faHeartCircleXmark,
    faTiktok,
    faTwitter,
    faGrid,
    faCircleInfo,
    fasCircleInfo,
    fasChevronDown,
    farCircleUser,
    fasCaretRight,
    faSpinner,
    faTags,
    faLink,
    faShuffle,
    faCameraMovie,
    faMegaphone,
    faWallet,
    faPencil,
    faRedo,
    faMessageLines,
    farComment,
    faExclamationTriangle,
    fassBadgeCheck,
    faFileVideo,
    fasCommentDollar,
    fasSackDollar,
    fasUserPlus,
    fasHandWave,
    fasCalendarClock,
    fasFaceGrinWide,
    faXTwitter,
    fasLockOpen,
    fasBadgeCheck,
    fasUserGroup,
    faCommentSmile,
    faBellSlash,
    farCommentSmile,
    fasPaperPlane,
    faChevronsUp,
    fasHandHoldingDollar,
    faChevronsDown,
    faBellSlash,
    fasLink,
    faGlobe,
    faSquareChevronRight,
    faSquareChevronDown,
    faCrown,
    fasCommentsDollar,
    fasCreditCardFront,
    fasFileInvoiceDollar,
    faUserSecret,
    fasPhotoFilm,
    fasExpandWide,
    fasVolume,
    fasVolumeXmark,
    fasSensorTriangleExclamation,
    fasSignalStream,
)

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel'
const pinia = createPinia()

const fontURL = 'https://use.typekit.net/zut8xvm.css'
const link = document.createElement('link')
link.rel = 'stylesheet'
link.href = fontURL
document.head.appendChild(link)

createInertiaApp({
    title: title => `${title} - ${appName}`,
    resolve: name =>
        resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')).then(
            page => {
                // Determine the layout based on the page name
                //const layout = name.startsWith('Profile/') ? ProfileLayout : Layout

                // Assign the layout only if page.default.layout is not already set
                page.default.layout = page.default.layout || Layout

                // Return the modified page
                return page
            },
        ),
    setup({ el, App, props, plugin }) {
        const disableRightClick = event => {
            // If the user is on the production environment, do not allow right-click
            // VITE_ENABLE_RIGHT_CLICK=true
            const ENABLE_RIGHT_CLICK = import.meta.env.VITE_ENABLE_RIGHT_CLICK

            if (ENABLE_RIGHT_CLICK == 'false') {
                event.preventDefault()
            }
        }
        const NSFW_POPUP = import.meta.env.VITE_NSFW_POPUP

        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(VueTailwindDatepicker)
            .use(pinia)
            .use(PrimeVue, {
                unstyled: true,
                pt: Tailwind,
            })
            .use(VueGoogleMaps, {
                load: {
                    key: import.meta.env.VITE_GOOGLE_MAPS_CLIENT_SECRET,
                    libraries: 'places',
                },
            })
            .component('font-awesome-icon', FontAwesomeIcon)
            .component('VueDatePicker', VueDatePicker)

        app.config.globalProperties.$disableRightClick = disableRightClick
        app.config.globalProperties.$NSFW_POPUP = NSFW_POPUP

        app.mount(el)
    },
    progress: {
        // The delay after which the progress bar will appear, in milliseconds...
        delay: 250,

        // The color of the progress bar...
        color: '#89e5a9',

        // Whether to include the default NProgress styles...
        includeCSS: true,

        // Whether the NProgress spinner will be shown...
        showSpinner: false,
    },
})

Inertia.on('navigate', event => {
    window.fathom.trackPageview()
})

//posthog init..
const API_KEY = import.meta.env.VITE_POSTHOG_API_KEY
const API_HOST = import.meta.env.VITE_POSTHOG_API_HOST
posthog.init(API_KEY, {
    api_host: API_HOST,
    autocapture: false,
    capture_pageview: false,
    capture_pageleave: false,
})
