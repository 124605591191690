<script setup>
    import { ref, onMounted, onUnmounted, computed, watch, nextTick } from 'vue'
    import ResponsiveNavLink from '@/Components/ResponsiveNavLink.vue'
    import { Link } from '@inertiajs/vue3'
    import ThemeToggle from '@/Components/ThemeToggle.vue'
    import FooterLinks from '@/Components/navigation/FooterLinks.vue'
    import { usePage } from '@inertiajs/vue3'
    import useSWRV from 'swrv'
    import AddCreditCard from '@/Components/payment/AddCreditCard.vue'

    const pathName = window.location.pathname
    const user = usePage().props.auth.user
    const { props } = usePage()

    const CONVERSATION_KEY = 'conversationsCount'
    const NOTIFICATION_KEY = 'notificationsCount'
    const WALLET_BALANCE_KEY = 'walletBalance'

    const fetcher = url => axios.get(url).then(res => res.data)

    import { initFlowbite } from 'flowbite'

    const emit = defineEmits(['logout', 'showFlyout', 'showCreatorFlyout'])

    const {} = defineProps({
        canLogin: {
            type: Boolean,
        },
        canRegister: {
            type: Boolean,
        },

        profileInitials: {
            type: String,
            default: 'OD',
        },
        isPendingVerification: {
            type: Boolean,
            default: false,
        },
        isLoggedIn: {
            type: Boolean,
            default: true,
        },
        isCreator: {
            type: Boolean,
            default: true,
        },
        verificationStatus: {
            type: Number,
            default: 0,
        },
    })
    const insideMenu = ref(null)
    const menuDiv = ref(null)
    const resetScrollPosition = () => {
        if (insideMenu.value) {
            setTimeout(() => {
                insideMenu.value.scrollTop = 0
            }, 300)
        }
    }
    onMounted(() => {
        initFlowbite()

        resetScrollPosition()
    })
    let fullName = ''
    if (props.auth && props.auth.user && props.auth.user.name) {
        fullName = props.auth.user.name
    }
    let initials = ''

    if (fullName) {
        const names = fullName.split(' ')
        initials = names.map(name => name.charAt(0)).join('')
        if (names.length === 1 && initials.length > 2) {
            initials = initials.substring(0, 2)
        }
        initials = initials.toLowerCase()
    }

    const profileInitials = ref(initials || props.profileIntails)
    const isMoreMenuOpen = ref(false)
    // Computed property to check if the user is logged in
    const isLoggedIn = ref(props.auth && props.auth.user && props.auth.user.name !== null)
    const { data: profile } = useSWRV(isLoggedIn.value ? '/profile/resource' : null, fetcher, {
        refreshInterval: 5000,
    })

    const isPendingVerification = props.isPendingVerification

    const menuItems = [
        { icon: 'stars', route: 'creator.index', label: 'Creators' },
        {
            icon: 'circle-bolt',
            route: 'profile.goLive',
            label: 'Live Girls',
            live: true,
        },
        { icon: 'magnifying-glass', route: 'explore.index', label: 'Explore' },
        {
            icon: 'coins',
            route: 'profile.wallet',
            label: 'Wallet',
            keyCount: WALLET_BALANCE_KEY,
            coins: 1,
        },
        { icon: 'cart-shopping', route: 'profile.paymentHistory', label: 'Purchases' },
        {
            icon: 'comment',
            route: 'chatify',
            label: 'Messages',
            count: props.auth.user.notifications_count,
            keyCount: CONVERSATION_KEY,
            params: {
                origin: pathName,
            },
            redirect: true,
        },
        {
            icon: 'bell',
            route: 'notifications',
            label: 'Notifications',
            count: props.auth.user.conversations_count,
            keyCount: NOTIFICATION_KEY,
        },
        { icon: 'bookmark', route: 'profile.collections', label: 'Saved' },
        {
            icon: 'heart',
            route: 'profile.show.likes',
            profile: props.auth.user.profile_url,
            label: 'Likes',
        }, //Need to be updated
        { icon: 'user-check', route: 'profile.fansAndFollowings', label: 'Following' },
        { icon: 'gear', route: 'profile.settings', label: 'Settings' },
    ]
    const openCreatorFly = () => {
        emit('showCreatorFlyout')
    }

    const getCountValue = (keyCount, profileData) => {
        if (keyCount == CONVERSATION_KEY) {
            return profileData?.conversations_count
        } else if (keyCount == NOTIFICATION_KEY) {
            return profileData?.notifications_count
        }
        return 0
    }

    const getCointsValue = (keyCount, profileData) => {
        if (keyCount == WALLET_BALANCE_KEY) {
            return profileData?.balance
        }
        return 0
    }

    const getVerifyText = status => {
        switch (status) {
            case 0:
                return 'Become a Creator'
            case 1:
                return 'Complete Verification'
            case 2:
                return 'Pending Approval'
        }
    }

    const showAddCardModal = ref(false)
    const openAddCardModal = () => {
        showAddCardModal.value = true
    }

    const closeAddCardModal = () => {
        showAddCardModal.value = false
    }
    const fathomClickLiveGirls = () => {
        fathom.trackEvent('NavMenuUser-click live-girls')
    }
    const fathomClickUserCreateBtn = () => {
        fathom.trackEvent('user_create_btn')
    }
</script>
<template>
    <!-- drawer component -->
    <div
        ref="menuDiv"
        id="right-navigation"
        class="fixed right-0 top-[-56px] z-[1001] block h-screen min-h-screen w-full translate-x-full overflow-hidden bg-transparent transition-transform dark:bg-transparent sm:top-[-85px]"
        tabindex="-1"
        aria-labelledby="drawer-right-label">
        <div
            class="close-menu-area absolute h-[200%] w-[21%] sm:w-[70%]"
            data-drawer-hide="right-navigation"></div>

        <div
            class="menuDiv absolute right-0 flex h-[100vh] min-h-screen w-[80%] flex-col bg-black sm:w-[476px]"
            :class="{ hidden: isMoreMenuOpen }"
            ref="insideMenu">
            <div
                id="insideMenu"
                class="insideMenu absolute right-0 top-0 z-[100] flex min-h-screen w-[100%] flex-col overflow-hidden bg-white px-[18px] pb-[100px] pt-[62px] dark:bg-black sm:w-[476px] sm:px-[81px] sm:pt-[40px]">
                <button
                    type="button"
                    @click="isMoreMenuOpen = false"
                    data-drawer-hide="right-navigation"
                    aria-controls="right-navigation"
                    class="absolute right-[17px] top-[17px] z-[1000] inline-flex h-[25px] w-[25px] items-center justify-center bg-transparent text-gray-400 hover:text-gray-900 focus:border-0 focus:bg-transparent focus:outline-none dark:hover:text-gray-900 lg:right-[83px] lg:top-[25px] xl:right-[57px] xl:top-[23px]">
                    <font-awesome-icon
                        class="h-[25px] w-[25px] text-iconWhite hover:text-iconhover sm:h-[35px] sm:w-[35px]"
                        :icon="['fas', 'xmark']" />
                </button>
                <!-- Logged In-->

                <div class="relative mx-auto w-full">
                    <div class="relative flex items-center justify-center">
                        <!-- Flex container -->
                        <div class="relative h-[105px] w-auto">
                            <Link
                                :href="
                                    route('profile.show', [$page.props.auth.user.profile_url], {
                                        backref: pathName,
                                    })
                                "
                                data-drawer-hide="right-navigation"
                                class="relative cursor-pointer">
                                <img
                                    v-if="props.avatarImage"
                                    :src="props.avatarImage"
                                    class="transition-filter duration-400 my-[20px] mr-[15px] h-[75px] w-[75px] cursor-pointer rounded-full transition-transform hover:scale-125 sm:h-[80px] sm:w-[80px]" />
                                <div v-else>
                                    <font-awesome-icon
                                        :icon="['fas', 'circle-user']"
                                        class="mx-auto my-[20px] h-[75px] w-[75px] cursor-pointer rounded-full bg-gray-300 text-neutral-700 hover:text-mintgreen sm:h-[80px] sm:w-[80px]" />
                                </div>
                            </Link>
                            <Link
                                v-if="props.creator?.isCreator"
                                data-drawer-hide="right-navigation"
                                class="absolute bottom-[0px] right-[10px] mx-auto block h-[20px] w-[20px] rounded-lg text-center dark:text-white"
                                :href="route('profile.edit', { backref: pathName })">
                                <font-awesome-icon
                                    class="h-[20px] w-[20px] hover:text-mintgreen"
                                    :icon="['far', 'circle-camera']" />
                            </Link>
                        </div>
                        <Link
                            :href="
                                route('profile.show', [$page.props.auth.user.profile_url], {
                                    backref: pathName,
                                })
                            "
                            data-drawer-hide="right-navigation"
                            class="ml-2 w-full max-w-[150px] overflow-hidden text-ellipsis text-[16px] font-normal tracking-[0.6px] dark:text-white dark:hover:text-mintgreen sm:text-[18px]">
                            {{ $page.props.auth.user.profile_url }}
                        </Link>
                    </div>
                </div>

                <div class="mb-[40px] mt-[40px] flex flex-col space-y-[30px]">
                    <!-- Create Girlfriend button -->
                    <Link
                        :href="route('gabber.create')"
                        data-drawer-hide="right-navigation"
                        class="mx-auto flex w-[290px] items-center justify-center space-x-2 rounded-full bg-[#E86599] px-8 py-3 text-white transition-colors duration-200 hover:bg-pink-600"
                        @click="fathomClickUserCreateBtn">
                        <img src="/images/waveform-icon.png" alt="Waveform" class="max-w-[20px]" />
                        <span class="whitespace-nowrap text-base font-medium"
                            >Create your own Girlfriend</span
                        >
                    </Link>

                    <template v-for="item in menuItems" :key="item.label">
                        <div v-if="item.live">
                            <!-- Has card on file-->
                            <a
                                v-if="item.route === 'profile.goLive'"
                                @click="fathomClickLiveGirls()"
                                href="https://exncams.com/"
                                class="menu-link relative flex w-full items-center border-l-4 border-transparent pr-4 text-left text-[16px] text-base font-medium text-gray-600 transition duration-150 ease-in-out focus:outline-none dark:text-white dark:hover:text-mintgreen sm:text-[18px]"
                                target="_blank"
                                rel="noopener noreferrer">
                                <font-awesome-icon
                                    :icon="['fas', item.icon]"
                                    class="mr-[14px] h-[20px] w-[20px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                                {{ item.label }}
                            </a>
                        </div>
                        <!-- No card on file
            <div v-else-if="item.live">

                <div
                    data-drawer-hide="right-navigation"
                    @click="openAddCardModal"
                    class="menu-link relative flex w-full cursor-pointer items-center border-l-4 border-transparent pr-4 text-left text-[16px] text-base font-medium text-gray-600 transition duration-150 ease-in-out focus:outline-none dark:text-white dark:hover:text-mintgreen sm:text-[18px]"
                    target="_blank"
                    rel="noopener noreferrer">
                    <font-awesome-icon
                        :icon="['fas', item.icon]"
                        class="mr-[14px] h-[20px] w-[20px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                    {{ item.label }}
                </div>
            </div>
            -->

                        <ResponsiveNavLink
                            v-if="item.route === 'profile.paymentHistory'"
                            :href="route(item.route, { tab: 'Subscriptions', backref: pathName })"
                            class="menu-link relative flex w-full items-center border-l-4 border-transparent pr-4 text-left text-[16px] text-base font-medium text-gray-600 transition duration-150 ease-in-out focus:outline-none dark:text-white dark:hover:text-mintgreen sm:text-[18px]"
                            target="_blank"
                            rel="noopener noreferrer"
                            data-drawer-hide="right-navigation">
                            <font-awesome-icon
                                :icon="['fas', item.icon]"
                                class="mr-[14px] h-[20px] w-[20px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                            {{ item.label }}
                        </ResponsiveNavLink>

                        <ResponsiveNavLink
                            v-else-if="item.route === 'profile.collections'"
                            :href="route(item.route, { tab: 'saved-videos', backref: pathName })"
                            class="menu-link relative flex w-full items-center border-l-4 border-transparent pr-4 text-left text-[16px] text-base font-medium text-gray-600 transition duration-150 ease-in-out focus:outline-none dark:text-white dark:hover:text-mintgreen sm:text-[18px]"
                            target="_blank"
                            rel="noopener noreferrer"
                            data-drawer-hide="right-navigation">
                            <font-awesome-icon
                                :icon="['fas', item.icon]"
                                class="mr-[14px] h-[20px] w-[20px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                            {{ item.label }}
                        </ResponsiveNavLink>

                        <ResponsiveNavLink
                            v-else
                            v-if="!item.redirect && !item.profile && !item.live"
                            @click="resetScrollPosition"
                            :href="route(item.route, { backref: pathName })"
                            class="relative flex items-center text-[16px] sm:text-[18px]"
                            data-drawer-hide="right-navigation">
                            <font-awesome-icon
                                :icon="['fas', item.icon]"
                                class="mr-[14px] h-[20px] w-[20px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                            {{ item.label }}
                            <div
                                v-if="getCountValue(item.keyCount, profile) > 0"
                                class="absolute right-0 top-[0px] leading-[40px]">
                                <span
                                    class="block h-[21px] w-[21px] rounded-full bg-alertred text-center text-[10px] font-medium leading-[21px] text-white">
                                    {{ getCountValue(item.keyCount, profile) }}
                                </span>
                            </div>
                            <div
                                v-if="item.coins"
                                class="absolute right-[15px] top-[2px] leading-[40px]">
                                <span
                                    class="block h-[21px] w-auto rounded-full text-center text-[18px] font-medium leading-[21px] text-white">
                                    ${{ getCointsValue(item.keyCount, profile) }}
                                </span>
                            </div>
                        </ResponsiveNavLink>
                        <ResponsiveNavLink
                            v-if="!item.redirect && item.profile"
                            :href="
                                route(item.route, [$page.props.auth.user.profile_url], {
                                    backref: pathName,
                                })
                            "
                            @click="resetScrollPosition"
                            class="relative flex items-center text-[16px] sm:text-[18px]"
                            data-drawer-hide="right-navigation">
                            <font-awesome-icon
                                :icon="['fas', item.icon]"
                                class="mr-[14px] h-[20px] w-[20px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                            {{ item.label }}
                            <div
                                v-if="getCountValue(item.keyCount, profile) > 0"
                                class="absolute right-0 top-[0px] leading-[40px]">
                                <span
                                    class="block h-[21px] w-[21px] rounded-full bg-alertred text-center text-[10px] font-medium leading-[21px] text-white">
                                    {{ getCountValue(item.keyCount, profile) }}
                                </span>
                            </div>
                            <div
                                v-if="item.coins"
                                class="absolute right-[15px] top-[2px] leading-[40px]">
                                <span
                                    class="block h-[21px] w-auto rounded-full text-center text-[18px] font-medium leading-[21px] text-white">
                                    ${{ item.coins }}
                                </span>
                            </div>
                        </ResponsiveNavLink>

                        <a
                            v-if="item.redirect"
                            :href="route(item.route, item.params, { backref: pathName })"
                            class="menu-link relative block flex w-full items-center border-l-4 border-transparent pr-4 text-left text-[16px] text-base font-medium text-gray-600 transition duration-150 ease-in-out focus:outline-none dark:text-white dark:hover:text-mintgreen sm:text-[18px]">
                            <font-awesome-icon
                                :icon="['fas', item.icon]"
                                class="mr-[14px] h-[20px] w-[20px] cursor-pointer text-gray-800 hover:text-gray-400 dark:text-white dark:hover:text-gray-400" />
                            {{ item.label }}
                            <div
                                v-if="getCountValue(item.keyCount, profile) > 0"
                                class="absolute right-0 top-[0px] leading-[40px]">
                                <span
                                    class="block h-[21px] w-[21px] rounded-full bg-alertred text-center text-[10px] font-medium leading-[21px] text-white">
                                    {{ getCountValue(item.keyCount, profile) }}
                                </span>
                            </div>
                            <div
                                v-if="item.coins"
                                class="absolute right-[15px] top-[2px] leading-[40px]">
                                <span
                                    class="block h-[21px] w-auto rounded-full text-center text-[18px] font-medium leading-[21px] text-white">
                                    ${{ item.coins }}
                                </span>
                            </div>
                        </a>
                    </template>
                </div>
                <div
                    @click="openCreatorFly()"
                    data-drawer-hide="right-navigation"
                    class="mx-auto mb-10 mt-5 block w-full max-w-[360px] cursor-pointer rounded-md bg-mintgreen text-center text-sm font-medium leading-[49px] tracking-wide text-black hover:bg-hovermintgreen sm:text-xl sm:leading-[57px]">
                    {{ getVerifyText(props.verificationStatus) }}
                </div>
                <div class="dark:text-white">
                    <theme-toggle />
                </div>
                <ResponsiveNavLink
                    class="py-[20px] text-[16px] sm:text-[16px]"
                    data-drawer-hide="right-navigation"
                    :href="route('logout')"
                    method="post"
                    as="button"
                    @click="$emit('logout')">
                    Log Out
                </ResponsiveNavLink>
                <FooterLinks />
            </div>
        </div>
    </div>

    <AddCreditCard :show="showAddCardModal" @close="closeAddCardModal" />
</template>
<style>
    .menuDiv,
    .insideMenu {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch; /* this is to enable momentum-based scrolling on iOS */
    }

    #insideMenu {
        transition: transform 0.1s ease-out;
    }
</style>
