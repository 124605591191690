<script setup>
    import Modal from '@/Components/Modal.vue'

    const emit = defineEmits(['closeModal'])

    const props = defineProps({
        show: {
            type: Boolean,
            default: true,
        },
    })

    const closeModal = () => {
        emit('closeModal')
    }
</script>
<template>
    <Modal :show="show" @close="closeModal" :closeable="true" z-index="z-[1002]">
        <div class="relative rounded-lg border border-darkgray bg-[#111111] shadow">
            <!-- Modal header -->
            <button @click="closeModal" class="absolute right-[5px] top-[5px] text-white">
                <font-awesome-icon
                    class="h-[25px] w-[25px] text-iconWhite hover:text-iconhover dark:hover:text-mintgreen sm:h-[35px] sm:w-[35px]"
                    :icon="['fas', 'xmark']" />
            </button>
            <div class="flex items-center justify-center text-center align-middle text-white">
                <div class="p-4 py-10 align-middle">
                    Sorry {{ $page.props.auth.user.name ?? $page.props.auth.user.profile_url }},
                    Live is unavailable, please contact:
                    <a
                        href="mailto:support@extranaughty.com?subject=own content"
                        class="dark:hover:text-mintgreen"
                        >support@extranaughty.com</a
                    >
                    for further assistance.
                </div>
            </div>
        </div>
    </Modal>
</template>
